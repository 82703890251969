import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  Inject,
  Injectable,
  Optional,
  PLATFORM_ID,
  signal,
} from '@angular/core';
import { THEME_TOKEN } from '@app/shared/models/constants/tokens';
import { CookieKeys } from '@app/shared/models/enums/cookie-keys.enum';
import { CookieService } from '@app/shared/services/cookie/cookie.service';
import { EMPTY, Observable } from 'rxjs';
import { LocalStorageKeys } from '../../models/enums/local-storage-keys.enum';
import { RootTheme, Theme } from '../../models/enums/theme.enum';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  public currentTheme = signal<Theme>(Theme.Auto);

  public rootTheme = signal<RootTheme>(
    this.theme ||
      (this.document.documentElement.dataset?.['theme'] as RootTheme) ||
      Theme.Light
  );

  private browserTheme: RootTheme;

  constructor(
    @Optional() @Inject(THEME_TOKEN) private theme: RootTheme,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private cookieService: CookieService,
    private localStorageService: LocalStorageService
  ) {}

  public setAppDefaultTheme(): Observable<never> {
    if (isPlatformBrowser(this.platformId)) {
      const autoDetectingTheme: boolean =
        this.localStorageService.getItem(LocalStorageKeys.AutoDetectingTheme) ??
        true;

      const mediaQueryList: MediaQueryList = window.matchMedia(
        '(prefers-color-scheme: dark)'
      );
      this.browserTheme = mediaQueryList.matches ? Theme.Dark : Theme.Light;

      if (autoDetectingTheme) {
        this.setAutoDetectingTheme(true);
        this.setRootTheme(this.browserTheme);
        this.currentTheme.set(Theme.Auto);

        return EMPTY;
      }

      this.currentTheme.set(this.rootTheme());
    }

    return EMPTY;
  }

  public setTheme(theme: Theme): void {
    this.currentTheme.set(theme);
    this.setAutoDetectingTheme(theme === Theme.Auto);
    this.setRootTheme(theme === Theme.Auto ? this.browserTheme : theme);
  }

  private setAutoDetectingTheme(autoDetecting: boolean): void {
    this.localStorageService.setItem(
      LocalStorageKeys.AutoDetectingTheme,
      autoDetecting
    );
  }

  private setRootTheme(theme: RootTheme): void {
    this.document.documentElement.dataset['theme'] = theme;
    this.cookieService.setCookie(CookieKeys.Theme, theme);
    this.rootTheme.set(theme);
  }
}

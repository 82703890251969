import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public setCookie(
    name: string,
    value: string,
    options: Record<string, string | Date | boolean | number> = {}
  ): void {
    const expireDate = new Date();
    expireDate.setFullYear(expireDate.getFullYear() + 1);

    options = {
      path: '/',
      samesite: 'lax',
      expires: expireDate.toUTCString(),
      ...options,
    };

    if (options['expires'] instanceof Date) {
      options['expires'] = options['expires'].toUTCString();
    }

    let updatedCookie =
      encodeURIComponent(name) + '=' + encodeURIComponent(value);

    for (let optionKey in options) {
      updatedCookie += '; ' + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += '=' + optionValue;
      }
    }

    this.document.cookie = updatedCookie;
  }

  public getCookie(name: string): string {
    let matches = this.document.cookie.match(
      new RegExp(
        '(?:^|; )' +
          name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
          '=([^;]*)'
      )
    );
    return matches ? decodeURIComponent(matches[1]) : '';
  }

  public deleteCookie(name: string): void {
    this.setCookie(name, '', {
      'max-age': -1,
    });
  }
}

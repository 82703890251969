import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  public setItem(key: string, value: any): void {
    if (!isPlatformBrowser(this.platformId)) return;

    localStorage.setItem(key, JSON.stringify(value));
  }

  public getItem(key: string): any {
    if (!isPlatformBrowser(this.platformId)) return;

    return this.parseJSON(localStorage.getItem(key));
  }

  public removeItem(key: string): void {
    if (!isPlatformBrowser(this.platformId)) return;

    localStorage.removeItem(key);
  }

  private parseJSON(str: string): any {
    try {
      return JSON.parse(str);
    } catch {
      return str;
    }
  }
}

export enum LocalStorageKeys {
  AllowCookie = 'allowCookie',
  AuthToken = 'authToken',
  AutoDetectingTheme = 'autoDetectingTheme',
  Language = 'lang',
  Pen2DHintActive = 'pen2DHintActive',
  Pen2DNavHintActive = 'pen2DNavHintActive',
  Pen3DHintActive = 'pen3DHintActive',
  Pen3DNavHintActive = 'pen3DNavHintActive',
  RecentlySearched = 'recentSearch',
  ReleaseNotesVersion = 'releaseNotesVersion',
  TrialStartPopupViewed = 'trialStartPopupViewed',
  TrialEndPopupViewed = 'trialEndPopupViewed',
  ViewerTooltipClosed = 'viewerTooltipClosed',
  ViewerTipsViewed = 'viewerTipsViewed',
}

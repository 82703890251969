import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Injector,
  Input,
  OnInit,
  effect,
} from '@angular/core';
import { ThemeService } from '../../services/theme/theme.service';

@Directive({
  selector: '[appColorThemeImage]',
  standalone: true,
})
export class ColorThemeImageDirective implements OnInit {
  @Input() public appColorThemeImage: { [key: string]: string };

  constructor(
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef<HTMLImageElement>,
    private injector: Injector,
    private themeService: ThemeService
  ) {}

  public ngOnInit(): void {
    effect(
      () => {
        this.elementRef.nativeElement.src =
          this.appColorThemeImage?.[this.themeService.rootTheme()];
        this.cdr.markForCheck();
      },
      { injector: this.injector }
    );
  }
}
